import React, { useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

import styles from "./styles.module.scss";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as Dashboard } from "../../assets/icons/dashboard.svg";
// import { ReactComponent as PriorAuth } from "../../assets/icons/prior-auth.svg";
// import { ReactComponent as Messenger } from "../../assets/icons/messenger.svg";
// import { ReactComponent as Accounts } from "../../assets/icons/accounts.svg";
// import { ReactComponent as Settings } from "../../assets/icons/settings.svg";

function SideBar() {
  const [active, setActive] = useState(0);

  const activeStyle = clsx(styles.btnStyle, styles.activeBtnStyle);

  const getBtnStyle = e => (active === e ? activeStyle : styles.btnStyle);
  return (
    <Drawer
      variant="permanent"
      open={true}
      classes={{
        paper: styles.container
      }}
    >
      <Link to="/">
        <IconButton className={styles.logoStyle}>
          <Logo />
        </IconButton>
      </Link>

      <Link to="/dashboard" className={styles.customLink}>
        <Button
          onClick={() => setActive(0)}
          className={getBtnStyle(0)}
          fullWidth
        >
          <Dashboard className={styles.iconStyle} />
          <label>Dashboard</label>
        </Button>
      </Link>

      {/* <Link to="/auth" className={styles.customLink}>
        <Button
          onClick={() => setActive(1)}
          className={getBtnStyle(1)}
          fullWidth
        >
          <PriorAuth className={styles.iconStyle} />
          <label>Prior Auth</label>
        </Button>
      </Link>
      <Link to="/messenger" className={styles.customLink}>
        <Button
          onClick={() => setActive(2)}
          className={getBtnStyle(2)}
          fullWidth
        >
          <Messenger className={styles.iconStyle} />
          <label>Messenger</label>
        </Button>
      </Link>
      <Link to="/accounts" className={styles.customLink}>
        <Button
          onClick={() => setActive(3)}
          className={getBtnStyle(3)}
          fullWidth
        >
          <Accounts className={styles.iconStyle} />
          <label>Manage Accounts</label>
        </Button>
      </Link>
      <Link to="/settings" className={styles.customLink}>
        <Button
          onClick={() => setActive(4)}
          className={getBtnStyle(4)}
          fullWidth
        >
          <Settings className={styles.iconStyle} />
          <label>Settings</label>
        </Button>
      </Link> */}
    </Drawer>
  );
}

const mapStateToProps = store => {
  return {};
};

const mapActionToProps = {};

SideBar.propTypes = {};

SideBar.defaultProps = {};

export default connect(mapStateToProps, mapActionToProps)(SideBar);
