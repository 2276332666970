import { createMuiTheme } from '@material-ui/core/styles';
import { Colors } from './themes/index.js';

const theme = createMuiTheme({
  palette: {
    primary: { main: Colors.primary },
    secondary: { main: Colors.green }
  },
  typography: {
    fontFamily: 'Roboto',
    useNextVariants: true,
    color: Colors.primary,
    h1: {
      color: Colors.primary,
    },
    h2: {
      color: '#308BFD',
      fontWeight: 900,
      fontSize: '21px',
    },
    h3: {
      color: Colors.primary,
    },
    h4: {
      color: Colors.primary,
    },
    h5: {
      color: Colors.primary,
    },
    h6: {
      color: Colors.primary,
    },
  },

});

export default theme;
