import React from 'react';

function Accounts(props) {
  return (
    <div className="App">
      Accounts PAGE
    </div>
  );
}

export default Accounts;
