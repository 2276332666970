import React from "react";
import GoogleMapReact from "google-map-react";
import FlexView from "react-flexview/lib";
import { Tooltip } from "@material-ui/core";
import { ReactComponent as SironaLogo } from "./SironaLogo.svg";

export const markers = [
  {
    numVisits: 1218,
    lat: 37.77741,
    lng: -122.418,
    name: "Rad Medical East"
  },
  {
    numVisits: 512,
    lat: 37.777752,
    lng: -122.440349,
    name: "Rad Medical West"
  },
  {
    numVisits: 932,
    lat: 37.775923,
    lng: -122.391978,
    name: "West Coast Rad"
  },
  {
    numVisits: 932,
    lat: 37.762386,
    lng: -122.412414,
    name: "Mission Clinic"
  }
];

const MyMarker = ({ name, numVisits }) => (
  <FlexView hAlignContent="center" column>
    <Tooltip title={`${numVisits} visits this week`}>
      <SironaLogo />
    </Tooltip>
    <span style={{ backgroundColor: "#F4F8F9", textAlign: "center" }}>
      {name}
    </span>
  </FlexView>
);

function GMapExample(props) {
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyCz4nAyJs-ftlwsuygewrD-jGoAeSDCssc" }}
      defaultZoom={14}
      defaultCenter={{ lat: 37.77, lng: -122.41 }}
    >
      {markers.map(({ numVisits, lat, lng, name }) => {
        return (
          <MyMarker numVisits={numVisits} lat={lat} lng={lng} name={name} />
        );
      })}
    </GoogleMapReact>
  );
}

export default GMapExample;
