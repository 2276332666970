import React from 'react';

function Settings(props) {
  return (
    <div className="App">
      Settings PAGE
    </div>
  );
}

export default Settings;
