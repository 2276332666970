const Colors = {
  primary: '#308BFD', // blue
  background_grey: '#F4F8F9',
  green: '#CFEDB5',
  dark_grey: 'rgba(0,0,0,0.4',
  black: '#2A3C41',
  
  btn_text: '#99A3AE',
  btn_background: '#79828B',
  error_red: '#EA827A',

};

export default Colors;
