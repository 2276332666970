import React, { useState } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import InfoIcon from "@material-ui/icons/Info";
import _ from "lodash";

import DropDown from "../Dropdown";
import styles from "./styles.module.scss";
import Typography from "@material-ui/core/Typography";

function SummaryCard({ summaryTitle, data, index, history, macra }) {
  const [selectValue, handleSelectValue] = useState("month");

  let selectedData = _.get(data, selectValue, "");
  let convertNum = _.includes(selectedData.durationChange, "+");

  return (
    <Paper className={styles.container}>
      <Grid container direction="row">
        <Grid item xs={6}>
          <h4 className={styles.title}>{summaryTitle}</h4>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          <DropDown
            value={selectValue}
            handleChange={val => {
              handleSelectValue(val.target.value);
            }}
          />
        </Grid>
      </Grid>
      {macra && (
        <Grid direction="row" alignItems="center" container>
          <InfoIcon fontSize="small" style={{ color: "#308BFD" }} />
          <Typography variant="caption">MACRA eligible</Typography>
        </Grid>
      )}

      <div className={styles.statContainer}>
        <h2 className={styles.summaryStat}>{selectedData.summaryStat}</h2>
      </div>

      <Divider />

      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={6}>
          <h4 className={styles.summaryLabel}>
            <span
              className={`${styles.durationStyle}  ${
                !convertNum ? styles.negativeChange : ""
              }`}
            >
              {selectedData.durationChange}
            </span>
            {selectValue}
          </h4>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          <Button
            variant="outlined"
            size="small"
            className={styles.buttonStyle}
            onClick={() => history.push(`/dashboard/${index + 1}`)}
          >
            more
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

SummaryCard.propTypes = {
  summaryTitle: PropTypes.string,
  data: PropTypes.object
};

SummaryCard.defaultProps = {
  summaryTitle: "",
  data: {}
};

export default SummaryCard;
