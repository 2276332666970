import React, { useState } from "react";
import FlexView from "react-flexview";
import { Chart } from "react-google-charts";
import { Button } from "@material-ui/core";
import ForceGraph2D from "react-force-graph-2d";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

const clinics = [
  {
    numVisits: 1218,
    name: "Palo Alto Clinic"
  },
  {
    numVisits: 512,
    lat: 37.777752,
    lng: -122.440349,
    name: "SOMA Urgent Care"
  },
  {
    numVisits: 932,
    lat: 37.775923,
    lng: -122.391978,
    name: "Tehama Primary Care"
  },
  {
    numVisits: 932,
    lat: 37.762386,
    lng: -122.412414,
    name: "Bernal Urgent Care"
  }
];

const forceGraphData = {
  nodes: [
    {
      id: "1",
      name: "Clayton Phillips (SIRONA)",
      color: "#3366cc",
      val: 1
    },
    {
      id: "2",
      name: "Joanna Haynes (SIRONA)",
      color: "#3366cc",
      val: 2
    },
    {
      id: "3",
      name: "Amanda Ramsey (SIRONA)",
      color: "#3366cc",
      val: 3
    },
    {
      id: "4",
      name: "Miranda Reed (SIRONA)",
      color: "#3366cc",
      val: 4
    },
    {
      id: "5",
      name: "Shannon Buchanan (SIRONA)",
      color: "#3366cc",
      val: 5
    },
    {
      id: "6",
      name: "Alfonso Hale (Tehama)",
      color: "purple",
      val: 6,
      isTarget: true
    },
    {
      id: "7",
      name: "Vincent Lawrence (SOMA)",
      color: "red",
      val: 7
    },
    {
      id: "8",
      name: "Alma Chapman (PA)",
      color: "green",
      val: 8
    },
    {
      id: "9",
      name: "Rachael Franklin (Tehama)",
      color: "purple",
      val: 9
    },
    {
      id: "10",
      name: "Terence Mclaughlin (SOMA)",
      color: "red",
      val: 10
    },
    {
      id: "11",
      name: "Jennie Mullins (PA)",
      color: "green",
      val: 11
    },
    {
      id: "12",
      name: "Mathew Leonard (Tehama)",
      color: "purple",
      val: 12,
      isTarget: true
    },
    {
      id: "13",
      name: "Brooke Fernandez (SOMA)",
      color: "red",
      val: 13
    },
    {
      id: "14",
      name: "Sean Houston (PA)",
      color: "green",
      val: 14
    },
    {
      id: "15",
      name: "Hope Lee (Tehama)",
      color: "purple",
      val: 15
    },
    {
      id: "16",
      name: "Clarence Rivera (SOMA)",
      color: "red",
      val: 16
    },
    {
      id: "17",
      name: "Rodolfo Fleming (PA)",
      color: "green",
      val: 17
    },
    {
      id: "18",
      name: "Curtis Harrington (Tehama)",
      color: "purple",
      val: 18
    },
    {
      id: "19",
      name: "Kurt Berry (SOMA)",
      color: "red",
      val: 19
    },
    {
      id: "20",
      name: "Penny Nash (PA)",
      color: "green",
      val: 20
    }
    // {
    //   id: "15",
    //   name: "Vicki Haynes",
    //   val: 21
    // },
    // {
    //   id: "15",
    //   name: "Hazel Harrison",
    //   val: 22
    // },
    // {
    //   id: "15",
    //   name: "Sonia Barrett",
    //   val: 15
    // },
    // {
    //   id: "15",
    //   name: "Domingo Reyes",
    //   val: 23
    // },
    // {
    //   id: "15",
    //   name: "Bobby Mcdonald",
    //   val: 24
    // },
    // {
    //   id: "15",
    //   name: "Stephen Lowe",
    //   val: 25
    // }
  ],
  links: [
    {
      source: "6",
      target: "1"
    },
    {
      source: "7",
      target: "1"
    },
    {
      source: "8",
      target: "1"
    },
    {
      source: "9",
      target: "2"
    },
    {
      source: "10",
      target: "2"
    },
    {
      source: "11",
      target: "2"
    },
    {
      source: "12",
      target: "3"
    },
    {
      source: "13",
      target: "3"
    },
    {
      source: "14",
      target: "3"
    },
    {
      source: "15",
      target: "4"
    },
    {
      source: "16",
      target: "4"
    },
    {
      source: "17",
      target: "4"
    },
    {
      source: "18",
      target: "5"
    },
    {
      source: "19",
      target: "5"
    },
    {
      source: "20",
      target: "5"
    },
    {
      source: "13",
      target: "4"
    }
  ]
};

function UniqueReferralDetail(props) {
  const [visualize, setVisualize] = useState(false);
  return (
    <FlexView column style={{ padding: "10px", backgroundColor: "#F4F8F9" }}>
      <FlexView style={{ padding: "10px" }}>
        <Button
          variant="outlined"
          style={{}}
          onClick={() => props.history.goBack()}
        >
          Back to overview
        </Button>
      </FlexView>
      <FlexView column hAlignContent="center">
        <FlexView style={{ width: "70%" }} column>
          {/** Main chart */}
          <FlexView>
            <FlexView width={"70%"}>
              <Chart
                width={"100%"}
                height={"400px"}
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={[
                  ["x", "Referrals"],
                  ["Jan", 10000],
                  ["Feb", 10000],
                  ["Mar", 15000],
                  ["Apr", 17000],
                  ["May", 18000],
                  ["Jun", 20000],
                  ["July", 23000],
                  ["Aug", 27000],
                  ["Sep", 33000],
                  ["Oct", 40000],
                  ["Nov", 45000],
                  ["Dec", 50000]
                ]}
                options={{
                  title: "Total referrals (hrs)",
                  hAxis: {
                    title: "Month"
                  },
                  vAxis: {
                    title: "Turnaround time"
                  },
                  curveType: "function"
                }}
                rootProps={{ "data-testid": "1" }}
              />
            </FlexView>
            <FlexView marginLeft="auto" column>
              <Typography variant="h6">Breakdown by clinic</Typography>
              <List>
                {clinics.map(({ numVisits, name }, idx) => {
                  return (
                    <>
                      <FlexView vAlignContent="center">
                        <FlexView>
                          <Typography variant="h5">{`${idx + 1}. `}</Typography>
                        </FlexView>
                        <FlexView>
                          <ListItem alignItems="flex-start">
                            <ListItemText
                              primary={name}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="textPrimary"
                                  >
                                    {numVisits}
                                  </Typography>
                                  {" referrals this month"}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        </FlexView>
                      </FlexView>
                      <Divider />
                    </>
                  );
                })}
              </List>
            </FlexView>
          </FlexView>

          {/** Force Graph */}
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Relationship map (Top 3 per radiologist)
          </Typography>
          <FlexView
            marginTop="10px"
            width={"70%"}
            height="400px"
            style={{ backgroundColor: "white" }}
            hAlignContent="center"
          >
            <ForceGraph2D
              width={615}
              height={400}
              graphData={forceGraphData}
              nodeAutoColorBy="group"
              nodeCanvasObject={(node, ctx, globalScale) => {
                const label = node.name;
                const fontSize = 12 / globalScale;
                ctx.font = `${fontSize}px Sans-Serif`;
                const textWidth = ctx.measureText(label).width;
                const bckgDimensions = [textWidth, fontSize].map(
                  n => n + fontSize * 0.2
                ); // some padding
                ctx.fillStyle = "rgba(255, 255, 255)";
                ctx.fillRect(
                  node.x - bckgDimensions[0] / 2,
                  node.y - bckgDimensions[1] / 2,
                  ...bckgDimensions
                );
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.fillStyle = node.color;
                ctx.fillText(label, node.x, node.y);
              }}
            />
          </FlexView>
        </FlexView>
      </FlexView>
    </FlexView>
  );
}

export default UniqueReferralDetail;
