import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
// import DetailedView from "./pages/Dashboard/DetailedView";
import Auth from "./pages/Auth";
import Messenger from "./pages/Messenger";
import Accounts from "./pages/Accounts";
import Settings from "./pages/Settings";
import Header from "./components/Header";
import SideBar from "./components/SideBar";
import "./App.css";
import NumCasesDetail from "./pages/Dashboard/NumCasesDetail";
import ReportTurnaroundDetail from "./pages/Dashboard/ReportTurnaroundDetail";
import UniqueReferralDetail from "./pages/Dashboard/UniqueReferralDetail";
import MacraDetail from "./pages/Dashboard/MacraDetail";

function App() {
  return (
    <div>
      <Router>
        <SideBar />
        <div style={{ marginLeft: 100 }}>
          <Header />
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/dashboard/1" component={NumCasesDetail} />
          <Route exact path="/dashboard/2" component={ReportTurnaroundDetail} />
          <Route exact path="/dashboard/3" component={UniqueReferralDetail} />
          <Route exact path="/dashboard/4" component={MacraDetail} />
          <Route exact path="/dashboard/5" component={MacraDetail} />
          <Route exact path="/dashboard/6" component={MacraDetail} />
          <Route exact path="/auth" component={Auth} />
          <Route exact path="/messenger" component={Messenger} />
          <Route exact path="/accounts" component={Accounts} />
          <Route exact path="/settings" component={Settings} />
        </div>
      </Router>
    </div>
  );
}

export default App;
