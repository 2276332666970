import { GET_DASHBOARD_DATA } from '../types';
import { takeLatest, put } from "redux-saga/effects";

import { putDashboardData, setDashboardFilters } from '../action';
import dashboardData from '../../fakeData/summarycards.json';

function* getDashboardDataSaga() {
    try {
        yield put(setDashboardFilters(dashboardData));
        yield put(putDashboardData(dashboardData));
    } catch (error) {
        console.log(error)
    }
}

export function* watchGlobal() {
    yield takeLatest(GET_DASHBOARD_DATA, getDashboardDataSaga);
}