import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';


import Colors from '../../themes/Colors'
import styles from './styles.module.scss';

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
        backgroundColor: Colors.primary,
        borderRadius: 8,
        position: 'relative',
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
    icon: {
        color: '#FFF',
        fill: '#FFF'
    }
  }),
)(InputBase);

function DropDown({ children, value, handleChange, ...rest }) {
    return (
        <Select native
            value={value}
            className={styles.container}
            input={<BootstrapInput />}
            onChange={handleChange}>
            <option value='month'>This Month</option>
            <option value='week'>This Week</option>
            <option value='today'>Today</option>
        </Select>
    );
}

DropDown.propTypes = {
  value: PropTypes.string,
};

DropDown.defaultProps = {
  value: ''
};

export default DropDown;
