import React from "react";
import FlexView from "react-flexview";
import { Chart } from "react-google-charts";
import { Button } from "@material-ui/core";
import GMapExample, { markers } from "./GMapExample";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as SironaLogo } from "./SironaLogo.svg";

function ReportTurnaroundDetail(props) {
  return (
    <FlexView column style={{ padding: "10px", backgroundColor: "#F4F8F9" }}>
      <FlexView style={{ padding: "10px" }}>
        <Button
          variant="outlined"
          style={{}}
          onClick={() => props.history.goBack()}
        >
          Back to overview
        </Button>
      </FlexView>
      <FlexView column hAlignContent="center">
        <FlexView style={{ width: "70%" }} column>
          {/** Main chart */}
          <FlexView>
            <Chart
              width={"100%"}
              height={"400px"}
              chartType="LineChart"
              loader={<div>Loading Chart</div>}
              data={[
                ["x", "Turnaround time (hrs)"],
                ["Jan", 23],
                ["Feb", 20],
                ["Mar", 16],
                ["Apr", 17],
                ["May", 18],
                ["Jun", 16],
                ["July", 13],
                ["Aug", 12],
                ["Sep", 11],
                ["Oct", 9],
                ["Nov", 8],
                ["Dec", 6]
              ]}
              options={{
                title: "Group Turnaround Time Average (hrs)",
                hAxis: {
                  title: "Month"
                },
                vAxis: {
                  title: "Turnaround time"
                },
                curveType: "function"
              }}
              rootProps={{ "data-testid": "1" }}
            />
          </FlexView>

          {/** breakdown row */}
          <FlexView marginTop="10px">
            <FlexView>
              <Chart
                width={"500px"}
                height={"400px"}
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={[
                  ["x", "MR", "CT", "Xray"],
                  ["Jan", 23, 20, 9],
                  ["Feb", 20, 18, 8],
                  ["Mar", 16, 14, 7],
                  ["Apr", 17, 13, 5],
                  ["May", 12, 10, 3]
                ]}
                options={{
                  title: "Modality Breakdown (hrs)",
                  hAxis: {
                    title: "Month"
                  },
                  vAxis: {
                    title: "Turnaround time"
                  },
                  curveType: "function"
                }}
                rootProps={{ "data-testid": "1" }}
              />
            </FlexView>
            <FlexView marginLeft="auto">
              <Chart
                width={"500px"}
                height={"400px"}
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={[
                  ["x", "Spine", "Shoulder", "Hip"],
                  ["Jan", 23, 20, 9],
                  ["Feb", 20, 18, 8],
                  ["Mar", 16, 14, 7],
                  ["Apr", 17, 13, 5],
                  ["May", 12, 10, 3]
                ]}
                options={{
                  title: "Anatomy Breakdown (hrs)",
                  hAxis: {
                    title: "Month"
                  },
                  vAxis: {
                    title: "Turnaround time"
                  },
                  curveType: "function"
                }}
                rootProps={{ "data-testid": "1" }}
              />
            </FlexView>
          </FlexView>
        </FlexView>
      </FlexView>
    </FlexView>
  );
}

export default ReportTurnaroundDetail;
