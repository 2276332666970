import React, { useState, useEffect, useRef, useCallback } from "react";
import Grid from "@material-ui/core/Grid";

import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import update from "immutability-helper";
import _ from "lodash";
import SummaryCards from "../../components/SummaryCard";

import { useDrag, useDrop } from "react-dnd";

const Card = ({ id, data, index, moveCard, history, macra }) => {
  const style = {
    // padding: '0.5rem 1rem',
    cursor: "move"
  };

  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: "card",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: "card", id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div ref={ref} style={{ ...style, opacity }}>
      <SummaryCards
        summaryTitle={id}
        data={data}
        history={history}
        index={index}
        macra={macra}
      />
    </div>
  );
};

function DraggableView(props) {
  const [cards, setCards] = useState(_.head(props.Data));

  useEffect(() => {
    setCards(props.Data);
  }, [props.Data]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex];
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard]
          ]
        })
      );
    },
    [cards]
  );

  const renderCard = (card, index) => {
    return (
      <Grid item xs={4} key={index}>
        <Card
          key={card.id}
          index={index}
          id={card.titleDisplay}
          data={card.data}
          macra={card.macra}
          moveCard={moveCard}
          history={props.history}
        />
      </Grid>
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Grid container spacing={3} style={{ marginTop: 10 }}>
        {_.map(cards, (card, i) => renderCard(card, i))}
      </Grid>
    </DndProvider>
  );
}

export default DraggableView;
