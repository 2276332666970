import React from 'react';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

import styles from './styles.module.scss';

function Header() {
    return (
        <AppBar position="static" className={styles.container}>
            <Toolbar style={{height:'100%'}}>
                <Typography component="h2" variant="h2" className={styles.titleText}>
                    Dashboard
                </Typography>
                <Button size='large' variant='text'>
                    <p className={styles.nameText}>Welcome, John Doe</p>
                    <Avatar alt='default-picture' className={styles.avatar}>
                        DP
                    </Avatar>
                </Button>
            </Toolbar>
        </AppBar>
    )
}


const mapStateToProps = store => {
	return {
	};
};

const mapActionToProps = {
};

Header.propTypes = {
};

Header.defaultProps = {
};

export default connect(mapStateToProps,mapActionToProps)(Header);
