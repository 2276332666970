import React from "react";
import FlexView from "react-flexview";
import { Chart } from "react-google-charts";
import { Button } from "@material-ui/core";
import GMapExample, { markers } from "./GMapExample";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as SironaLogo } from "./SironaLogo.svg";

function NumCasesDetail(props) {
  return (
    <FlexView column style={{ padding: "10px", backgroundColor: "#F4F8F9" }}>
      <FlexView style={{ padding: "10px" }}>
        <Button
          variant="outlined"
          style={{}}
          onClick={() => props.history.goBack()}
        >
          Back to overview
        </Button>
      </FlexView>
      <FlexView column hAlignContent="center">
        <FlexView style={{ width: "70%" }} column>
          {/** Main chart */}
          <FlexView>
            <Chart
              width={"100%"}
              height={"400px"}
              chartType="LineChart"
              loader={<div>Loading Chart</div>}
              data={[
                ["x", "Cases"],
                ["Jan", 10000],
                ["Feb", 10000],
                ["Mar", 15000],
                ["Apr", 17000],
                ["May", 18000],
                ["Jun", 20000],
                ["July", 23000],
                ["Aug", 27000],
                ["Sep", 33000],
                ["Oct", 40000],
                ["Nov", 45000],
                ["Dec", 50000]
              ]}
              options={{
                title: "Group Average",
                hAxis: {
                  title: "Month"
                },
                vAxis: {
                  title: "Cases"
                },
                curveType: "function"
              }}
              rootProps={{ "data-testid": "1" }}
            />
          </FlexView>

          {/** breakdown row */}
          <FlexView marginTop="10px">
            <FlexView>
              <Chart
                width="500px"
                height="300px"
                chartType="PieChart"
                data={[
                  ["Task", "Hours per Day"],
                  ["CT", 11],
                  ["MR", 9],
                  ["Xray", 2],
                  ["PET", 1]
                ]}
                options={{
                  title: "Modality breakdown",
                  pieHole: 0.4,
                  is3D: false
                }}
              />
            </FlexView>
            <FlexView marginLeft="auto">
              <Chart
                width="500px"
                height="300px"
                chartType="PieChart"
                data={[
                  ["Task", "Hours per Day"],
                  ["Spine", 11],
                  ["Hand", 5],
                  ["Shoulder", 2],
                  ["Hip", 1]
                ]}
                options={{
                  title: "Anatomy breakdown",
                  pieHole: 0.4,
                  is3D: false
                }}
              />
            </FlexView>
          </FlexView>

          {/** maps row */}
          <FlexView
            column
            style={{ backgroundColor: "white" }}
            marginTop="10px"
          >
            <Typography
              style={{ padding: "5px", color: "black", textAlign: "center" }}
              variant="h6"
            >
              Site Breakdown
            </Typography>
            <FlexView>
              <FlexView height="500px" width="80%" marginTop="10px">
                <GMapExample />
              </FlexView>
              <FlexView marginLeft="auto">
                <List>
                  {markers.map(({ numVisits, name }) => {
                    return (
                      <>
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <SironaLogo />
                          </ListItemAvatar>
                          <ListItemText
                            primary={name}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="textPrimary"
                                >
                                  {numVisits}
                                </Typography>
                                {" visits this week"}
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </>
                    );
                  })}
                </List>
              </FlexView>
            </FlexView>
          </FlexView>
        </FlexView>
      </FlexView>
    </FlexView>
  );
}

export default NumCasesDetail;
