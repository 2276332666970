import {
    PUT_DASHBOARD_DATA,
    GET_DASHBOARD_DATA,
    SET_DASHBOARD_FILTERS
} from '../types'

  const initialState = {
      isLoading: false,
      headerTitle: 'Siron Insights',
      dashboardData: [],
      dashboardFilterData: []
  }

export default (state = initialState, action) => {
    switch(action.type) {

        case GET_DASHBOARD_DATA:
            return { ...state }
        case PUT_DASHBOARD_DATA:
            return { ...state, dashboardData: action.payload }
        case SET_DASHBOARD_FILTERS:
            return { ...state, dashboardFilterData: action.payload }
        default:
            return state;  
    }
}