import React from 'react';

function Messenger(props) {
  return (
    <div className="App">
      Messenger PAGE
    </div>
  );
}

export default Messenger;
