import React, { useState } from "react";
import FlexView from "react-flexview";
import { Chart } from "react-google-charts";
import { Button } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

const clinics = [
  {
    numVisits: "96%",
    name: "Evan Stevens"
  },
  {
    numVisits: "92%",
    name: "Dorothy Baldwin"
  },
  {
    numVisits: "86%",
    name: "Kelley Medina"
  },
  {
    numVisits: "76%",
    name: "Elaine Dean"
  }
];

function MacraDetail(props) {
  return (
    <FlexView column style={{ padding: "10px", backgroundColor: "#F4F8F9" }}>
      <FlexView style={{ padding: "10px" }}>
        <Button
          variant="outlined"
          style={{}}
          onClick={() => props.history.goBack()}
        >
          Back to overview
        </Button>
      </FlexView>
      <FlexView column hAlignContent="center">
        <FlexView style={{ width: "70%" }} column>
          {/** Main chart */}
          <FlexView>
            <FlexView width={"70%"}>
              <Chart
                width={"100%"}
                height={"400px"}
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={[
                  ["x", "Compliance rate"],
                  ["Jan", 30],
                  ["Feb", 35],
                  ["Mar", 40],
                  ["Apr", 50],
                  ["May", 52],
                  ["Jun", 60],
                  ["July", 70],
                  ["Aug", 80],
                  ["Sep", 85],
                  ["Oct", 90],
                  ["Nov", 92],
                  ["Dec", 97]
                ]}
                options={{
                  title: "Percent of reports that are compliant",
                  hAxis: {
                    title: "Month"
                  },
                  vAxis: {
                    title: "Report compliance"
                  },
                  curveType: "function"
                }}
                rootProps={{ "data-testid": "1" }}
              />
            </FlexView>
            <FlexView marginLeft="auto" column>
              <Typography variant="h6">Breakdown by physician</Typography>
              <List>
                {clinics.map(({ numVisits, name }, idx) => {
                  return (
                    <>
                      <FlexView vAlignContent="center">
                        <FlexView>
                          <Typography variant="h5">{`${idx + 1}. `}</Typography>
                        </FlexView>
                        <FlexView>
                          <ListItem alignItems="flex-start">
                            <ListItemText
                              primary={name}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="textPrimary"
                                  >
                                    {numVisits}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        </FlexView>
                      </FlexView>
                      <Divider />
                    </>
                  );
                })}
              </List>
            </FlexView>
          </FlexView>
        </FlexView>
      </FlexView>
    </FlexView>
  );
}

export default MacraDetail;
