import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Popover from "@material-ui/core/Popover";
import { withStyles, Theme } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import _ from "lodash";

import { getDashboardData, setDashboardFilters } from "../../redux/action";
import DraggableView from "./DraggableView";
import ConfigureDashboard from "./ConfigureDashboard";
import styles from "./styles.module.scss";

const NotifButton = withStyles({
  root: {
    backgroundColor: "#4DA1FF",
    boxShadow: "1px 2px 17px 0 rgba(0,0,0,0.07)",
    borderRadius: 4,
    height: 75,
    width: 50
  }
})(Button);

const StyledBadge = withStyles((theme: Theme) => ({
  badge: {
    backgroundImage: "linear-gradient(180deg, #FF7878 0%, #FF4F4F 100%)",
    color: "#FFF"
  }
}))(Badge);

const notifications = [
  {
    time: "10.15. 19 | 9:59 am",
    notifText: "Number of cases increased 40% this month."
  },
  {
    time: "10.15. 19 | 9:59 am",
    notifText: "Report turnaround time up 5% this month."
  },
  {
    time: "10.15. 19 | 9:59 am",
    notifText: "Referral rate from San Francisco General down 10% this month."
  }
];

function Dashboard(props) {
  const [openConfigure, setConfigureVisibility] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  let [activeTabs, setActiveTabs] = useState([]);

  let { globalReducer, getDashboardData } = props;

  useEffect(() => {
    getDashboardData();
  }, []);

  useEffect(() => {
    if (activeTabs !== globalReducer.dashboardFilterData) {
      setActiveTabs(globalReducer.dashboardFilterData);
    } else {
      setActiveTabs(activeTabs);
    }
  }, [globalReducer.dashboardFilterData]);

  const notifsToRender = notifications.map((notif, index) => {
    return (
      <div className={styles.notifContainer} key={index}>
        <Divider className={styles.divider} />
        <span className={styles.notifTimeStamp}>{notif.time}</span>
        <p className={styles.notifTitle}>{notif.notifText}</p>
        <Button
          onClick={() => props.history.push("/dashboard/" + (index + 1))}
          className={styles.dismissViewBtn}
        >
          View
        </Button>
      </div>
    );
  });

  const setDashboardTabs = (data, checked) => {
    if (!checked) {
      activeTabs = [...activeTabs, data];
    } else {
      activeTabs = _.remove(_.clone(activeTabs), val => val.id !== data.id);
    }
    setActiveTabs(activeTabs);
  };

  const setFilters = data => {
    let params = data ? activeTabs : globalReducer.dashboardFilterData;
    props.setDashboardFilters(params);
    setConfigureVisibility(false);
    setActiveTabs(params);
  };

  return (
    <div className={styles.container}>
      <Grid container>
        <Grid item xs={9}>
          <Grid container spacing={2} className={styles.overviewContainer}>
            <Grid item xs={12} sm={6}>
              <h2 className={styles.titleStyle}>Overview</h2>
            </Grid>

            <Grid item xs={12} sm={6} className={styles.buttonContainer}>
              <Button
                variant="contained"
                className={styles.configBtn}
                onClick={(event, mouse) => {
                  setConfigureVisibility(!openConfigure);
                  setAnchorEl(event.currentTarget);
                }}
              >
                <i className={`fas fa-cog ${styles.iconStyle}`}></i>
                Configure Dashboard
              </Button>
              <Popover
                anchorEl={anchorEl}
                open={openConfigure}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
              >
                <ConfigureDashboard
                  data={_.cloneDeep(globalReducer.dashboardData)}
                  setDashboardTabs={setDashboardTabs}
                  activeTabs={activeTabs}
                  setActiveTabs={setActiveTabs}
                  setFilters={setFilters}
                />
              </Popover>
            </Grid>

            <DraggableView
              Data={globalReducer.dashboardFilterData}
              history={props.history}
            />
          </Grid>
        </Grid>
        <Grid item xs={3} className={styles.notificationContainer}>
          <div className={styles.notifHeaderContainer}>
            <NotifButton variant="contained">
              <StyledBadge badgeContent={4}>
                <i className={`far fa-bell ${styles.notifIconStyle}`}></i>
              </StyledBadge>
            </NotifButton>
            <span className={styles.notifTextStyle}>Notifications</span>
          </div>
          {notifsToRender}
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = store => {
  return {
    globalReducer: store.globalReducer
  };
};

const mapActionToProps = {
  getDashboardData,
  setDashboardFilters
};

Dashboard.propTypes = {
  getDashboardData: PropTypes.func,
  setDashboardFilters: PropTypes.func,
  globalReducer: PropTypes.object
};

Dashboard.defaultProps = {
  getDashboardData: () => {},
  setDashboardFilters: () => {},
  globalReducer: {}
};

export default connect(mapStateToProps, mapActionToProps)(Dashboard);
