import React, { useState } from "react";
import { createStyles, fade, Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import InputBase from '@material-ui/core/InputBase';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from "@material-ui/core/Button";
import _ from 'lodash';

import styles from "./configureStyles.module.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      position: 'relative',
      marginBottom: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      background: '#FFFFFF',
      border: '2px solid #F0F0F0',
      borderRadius: 4,
      fontFamily: 'Helvetica',
      fontSize: 13,
      color: '#B2B7BD',
      letterSpacing: 0.3,
      width: '100%',
    },
    inputInput: {
      width: '100%',
      padding: theme.spacing(1, 1, 1, 1),
    },
  }),
);

const CustomButton = withStyles({
  root: {
    fontFamily: 'Roboto-Black',
    fontSize: 13,
    color: '#649FFF',
    letterSpacing: 0.7,
  }
})(Button);

const PrimaryButton = withStyles({
  root: {
    background: '#308BFD',
    borderRadius: 16,
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    color: '#FFFFFF',
    letterSpacing: 0,
    textAlign: 'center',
  }
})(Button);

const SecondaryButton = withStyles({
  root: {
    fontFamily: 'Roboto-Light',
    fontSize: 13,
    color: '#4C95FF',
    letterSpacing: 0.7,
    marginRight: 5
  }
})(Button);

const CustomCbxLabel = withStyles({
  root: {
    fontFamily: 'Roboto-Light',
    fontSize: 14,
    color: '#000000',
    letterSpacing: 0.3,
  }
})(FormControlLabel);

function ConfigureDashboard(props) {
  let [searchValue, setSearchValue] = useState('');
  const classes = useStyles();

  return (
    <div className={styles.container}>
      <div className={classes.search}>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
      </div>
      <CustomButton onClick={() => props.setActiveTabs(props.data)}>
        Select All
      </CustomButton>
      <CustomButton onClick={() => props.setActiveTabs([])} style={{float: 'right'}}>
        Clear
      </CustomButton>

      <div className={styles.checkboxContainer}>
        {_.map(props.data, (data, idx) => {
          let activeCbx = _.find(props.activeTabs, val => val.id === data.id)
          return <CustomCbxLabel
            key={idx}
            value={data.titleDisplay}
            control={<Checkbox color="primary" 
              checked={Boolean(activeCbx)}
              onChange={() => props.setDashboardTabs(data, activeCbx)}
              />}
            label={data.titleDisplay}
            labelPlacement="end"
          />
        })}
      </div>
      
      <div className={styles.actionBtnContainer}>
        <SecondaryButton onClick={() => props.setFilters(false)}>
          Cancel
        </SecondaryButton>
        <PrimaryButton onClick={() => props.setFilters(true)}>
          Done
        </PrimaryButton>
      </div>
    </div>
  );
}

ConfigureDashboard.propTypes = {
  setDashboardTabs: PropTypes.func,
  setActiveTabs: PropTypes.func,
  setFilters: PropTypes.func,
  data: PropTypes.array,
  activeTabs: PropTypes.array,
};

ConfigureDashboard.defaultProps = {
};

export default ConfigureDashboard;
