import {
  GET_DASHBOARD_DATA,
  PUT_DASHBOARD_DATA,
  SET_DASHBOARD_FILTERS
} from '../types'

export const getDashboardData = payload => {
  return {
    type: GET_DASHBOARD_DATA,
    payload,
  }
}

export const putDashboardData = payload => {
  return {
    type: PUT_DASHBOARD_DATA,
    payload,
  }
}

export const setDashboardFilters = payload => {
  return {
    type: SET_DASHBOARD_FILTERS,
    payload,
  }
}