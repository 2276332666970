import React from 'react';

function Auth(props) {
  return (
    <div className="App">
      AUTH PAGE
    </div>
  );
}

export default Auth;
